import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import DefaultFooter from "examples/Footers/DefaultFooter";
import { CardContent, IconButton, Paper, Stack, Tooltip } from "@mui/material";
import footerRoutes from "footer.routes";
import { Link } from "react-router-dom";
import ScraperFeedTablePage from "./components/ScraperFeedTable";
import ChyronFeedTablePage from "./components/ChyronFeedTable";
import ContractsTablePage from "./components/ContractsTable";
import FDATablePage from "./components/FDATable";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useState } from "react";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import PatentsTable from "pages/SecondPage/components/PatentsTable";
import NitterPage from "pages/SecondPage/components/NitterTable";
import SettingLayout from "./components/SettingLayout";
import SettingHeadline from "./components/ChyronFeedTable/Setting";

const CHYRON_FEED = "Chyron Feed";
const FDA = "FDA/Orphans";
const CONTRACTS = "Contracts";
const PATENTS = "Patents";
const TWITTER = "Twitter";
const ARTICLES = "Articles";

const CNN = "CNN";
const CNNUS = "CNNUS";
const CNBC = "CNBC";
const CNBCUS = "CNBCUS";
const MSNBC = "MSNBC";
const FoxNews = "FoxNews";
const FoxBusiness = "FoxBusiness";
const Bloomberg = "Bloomberg";

const Presentation = () => {
  const [contractSound, setContractSound] = useState(false);
  const [fdaSound, setFdaSound] = useState(false);
  const [chyronFeedPause, setChyronFeedPause] = useState(false);
  const [twitterSound, setTwitterSound] = useState(false);
  const [selectedTables, setSelectedTables] = useState(
    (localStorage.getItem("tables") || [CHYRON_FEED, ARTICLES, TWITTER, PATENTS].join(",")).split(
      ","
    )
  );
  const [selectedChyrons, setSelectedChyrons] = useState(
    (
      localStorage.getItem("chyronlist") ||
      [CNN, CNNUS, CNBC, CNBCUS, MSNBC, FoxNews, FoxBusiness, Bloomberg].join(",")
    ).split(",")
  );

  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      ></MKBox>
      <Grid container>
        {selectedTables.includes(CHYRON_FEED) && (
          <Grid item xl={6} sm={12} lg={6} md={12} mt={2} xs={12} sx={{ marginTop: 1 }}>
            <Paper
              elevation={2}
              sx={{
                margin: 1,
              }}
            >
              <Card sx={{ minWidth: 275 }}>
                <div style={{ height: 40, width: "100%", padding: "1rem" }}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <SettingHeadline
                      selectedChyrons={selectedChyrons}
                      setSelectedChyrons={setSelectedChyrons}
                    />
                    <Link to={"/search"} target="_blank">
                      <Tooltip title="Advanced Search" arrow>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <Link to={"/livetv"} target="_blank">
                      <Tooltip title="Live TV" arrow>
                        <IconButton>
                          <LiveTvIcon />
                        </IconButton>
                      </Tooltip>
                    </Link>
                    {chyronFeedPause ? (
                      <Tooltip title="Off" arrow>
                        <IconButton
                          onClick={() => {
                            setChyronFeedPause(!chyronFeedPause);
                          }}
                        >
                          <PlayCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="On" arrow>
                        <IconButton
                          onClick={() => {
                            setChyronFeedPause(!chyronFeedPause);
                          }}
                        >
                          <PauseCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </div>
                <CardContent sx={{ padding: "1rem" }}>
                  <div style={{ height: window.innerHeight / 2, width: "100%" }}>
                    <ChyronFeedTablePage
                      isPause={chyronFeedPause}
                      selectedChyrons={selectedChyrons}
                    />
                  </div>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}

        {selectedTables.includes(CONTRACTS) && (
          <Grid item xl={6} sm={12} lg={6} md={12} mt={2} xs={12} sx={{ marginTop: 1 }}>
            <Paper
              elevation={2}
              sx={{
                margin: 1,
              }}
            >
              <Card sx={{ minWidth: 275 }}>
                <div style={{ height: 40, width: "100%", padding: "1rem" }}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    {contractSound ? (
                      <Tooltip title="Off" arrow>
                        <IconButton
                          onClick={() => {
                            setContractSound(!contractSound);
                          }}
                        >
                          <VolumeUpIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="On" arrow>
                        <IconButton
                          onClick={() => {
                            setContractSound(!contractSound);
                          }}
                        >
                          <VolumeOffIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </div>
                <CardContent sx={{ padding: "1rem" }}>
                  <div style={{ height: window.innerHeight / 2, width: "100%" }}>
                    <ContractsTablePage sound={contractSound} />
                  </div>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}

        {selectedTables.includes(FDA) && (
          <Grid item xl={6} sm={12} lg={6} md={12} mt={2} xs={12} sx={{ marginTop: 1 }}>
            <Paper
              elevation={2}
              sx={{
                margin: 1,
              }}
            >
              <Card sx={{ minWidth: 275 }}>
                <div style={{ height: 40, width: "100%", padding: "1rem" }}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    {fdaSound ? (
                      <Tooltip title="Off" arrow>
                        <IconButton
                          onClick={() => {
                            setFdaSound(!fdaSound);
                          }}
                        >
                          <VolumeUpIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="On" arrow>
                        <IconButton
                          onClick={() => {
                            setFdaSound(!fdaSound);
                          }}
                        >
                          <VolumeOffIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </div>
                <CardContent sx={{ padding: "1rem" }}>
                  <div style={{ height: window.innerHeight / 2, width: "100%" }}>
                    <FDATablePage />
                  </div>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}

        {selectedTables.includes(ARTICLES) && (
          <Grid item xl={6} sm={12} lg={6} md={12} mt={2} xs={12} sx={{ marginTop: 1 }}>
            <Paper
              elevation={2}
              sx={{
                margin: 1,
              }}
            >
              <Card sx={{ minWidth: 275 }}>
                <CardContent sx={{ padding: "1rem" }}>
                  <div style={{ height: window.innerHeight / 2, width: "100%" }}>
                    <ScraperFeedTablePage />
                  </div>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}

        {selectedTables.includes(PATENTS) && (
          <Grid item xl={6} sm={12} lg={6} md={12} mt={2} xs={12} sx={{ marginTop: 1 }}>
            <Paper
              elevation={2}
              sx={{
                margin: 1,
              }}
            >
              <Card sx={{ minWidth: 275 }}>
                <CardContent sx={{ padding: "1rem" }}>
                  <div style={{ height: window.innerHeight / 2, width: "100%" }}>
                    <PatentsTable />
                  </div>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}

        {selectedTables.includes(TWITTER) && (
          <Grid item xl={6} sm={12} lg={6} md={12} mt={2} xs={12} sx={{ marginTop: 1 }}>
            <Paper
              elevation={2}
              sx={{
                margin: 1,
              }}
            >
              <Card sx={{ minWidth: 275 }}>
                <div style={{ height: 40, width: "100%", padding: "1rem" }}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    {twitterSound ? (
                      <Tooltip title="Off" arrow>
                        <IconButton
                          onClick={() => {
                            setTwitterSound(!twitterSound);
                          }}
                        >
                          <VolumeUpIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="On" arrow>
                        <IconButton
                          onClick={() => {
                            setTwitterSound(!twitterSound);
                          }}
                        >
                          <VolumeOffIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </div>
                <CardContent sx={{ padding: "1rem" }}>
                  <div style={{ height: window.innerHeight / 2, width: "100%" }}>
                    <NitterPage />
                  </div>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <SettingLayout selectedTables={selectedTables} setSelectedTables={setSelectedTables} />
    </>
  );
};

export default Presentation;
