import PropTypes from "prop-types";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const ContentCell = ({ value }) => {
  return (
    <Link to={`https://${process.env.REACT_APP_DOMAIN}/${value}`} target="_blank">
      <Button startIcon={<DownloadIcon />} />
    </Link>
  );
};

ContentCell.propTypes = {
  value: PropTypes.string,
};

export default ContentCell;
