import useWebSocket from "react-use-websocket";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { useState } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import DateTimeCell from "./DateTimeCell";
import TextCell from "./TextCell";
import URLCell from "./URLCell";
import { alpha, styled } from "@mui/material/styles";
import { useSpeechSynthesis } from "react-speech-kit";
import PropTypes from "prop-types";
const ODD_OPACITY = 0.1;

const FDATablePage = ({ sound }) => {
  const [clientID, setClientID] = useState("");
  const [chyronData, setChyronData] = useState([]);
  const { lastJsonMessage } = useWebSocket(
    `wss://${process.env.REACT_APP_DOMAIN}/ws/fda/?client=${clientID}`,
    {
      onOpen: () => setChyronData([]),
      shouldReconnect: () => true,
    }
  );
  const { speak } = useSpeechSynthesis();

  useEffect(() => {
    setClientID(uuidv4());
  }, []);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.length === undefined) {
        setChyronData((prev) => [lastJsonMessage].concat(prev));
        if (sound) speak({ text: "A new FDA item is found" });
      } else {
        setChyronData((prev) => lastJsonMessage.concat(prev));
      }
    }
  }, [lastJsonMessage, setChyronData]);

  const getRows = () => {
    return chyronData.map((item, index) => ({
      id: index,
      col1: item["time"],
      col2: item["company"],
      col3: item["drug"],
      col4: item["approval_date"],
      col5: item["url"],
    }));
  };

  const columns = [
    { field: "col1", headerName: "Date", flex: 1, renderCell: DateTimeCell },
    { field: "col2", headerName: "Company", flex: 1, renderCell: TextCell },
    { field: "col3", headerName: "Drug/Orphan Designation", flex: 2, renderCell: TextCell },
    { field: "col4", headerName: "App/Orphan Date", flex: 1, renderCell: DateTimeCell },
    { field: "col5", headerName: "URL", flex: 1, renderCell: URLCell },
  ];

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[300],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  return (
    <StripedDataGrid
      rows={getRows()}
      columns={columns}
      getRowHeight={() => "auto"}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
    />
  );
};

FDATablePage.propTypes = {
  sound: PropTypes.bool,
};

export default FDATablePage;
