import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import SettingsIcon from "@mui/icons-material/Settings";

const CHYRON_FEED = "Chyron Feed";
const FDA = "FDA/Orphans";
const CONTRACTS = "Contracts";
const PATENTS = "Patents";
const TWITTER = "Twitter";
const ARTICLES = "Articles";
const tableList = [CHYRON_FEED, FDA, CONTRACTS, PATENTS, TWITTER, ARTICLES];

const SettingLayout = ({ selectedTables, setSelectedTables }) => {
  const [openSettings, setOpenSettings] = useState(false);
  return (
    <>
      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        <Fab
          color="secondary"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
          }}
          onClick={() => {
            setOpenSettings(true);
          }}
        >
          <SettingsIcon fontSize="large" />
        </Fab>
      </Box>

      <Dialog
        open={openSettings}
        onClose={() => {
          setOpenSettings(false);
        }}
        width="lg"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Settings"}</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
            <FormLabel component="label">Show Tables</FormLabel>
            <FormGroup>
              <Grid container spacing={2}>
                {tableList.map((item) => (
                  <Grid item key={item}>
                    <FormControlLabel
                      md={3}
                      xs={1}
                      lg={2}
                      control={
                        <Checkbox
                          checked={selectedTables.includes(item)}
                          onChange={() => {
                            let newSelectedTables;
                            if (selectedTables.includes(item)) {
                              newSelectedTables = selectedTables.filter(
                                (tableName) => tableName != item
                              );
                            } else {
                              newSelectedTables = selectedTables.concat(item);
                            }
                            setSelectedTables(newSelectedTables);
                            localStorage.setItem("tables", newSelectedTables.join(","));
                          }}
                          name={item}
                        />
                      }
                      label={item}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSettings(false);
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SettingLayout.propTypes = {
  selectedTables: PropTypes.array,
  setSelectedTables: PropTypes.func,
};

export default SettingLayout;
