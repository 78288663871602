import PropTypes from "prop-types";
import { styled } from "@mui/system";

const MyComponent = styled("div")({
  whiteSpace: "pre-wrap",
  fontWeight: "bolder",
});

const ContentCell = ({ value }) => {
  const urlify = (text) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a target="blank" href="' + url + '">' + url + "</a>";
    });
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  };
  return (
    <MyComponent>
      <div dangerouslySetInnerHTML={{ __html: urlify(value) }} />
    </MyComponent>
  );
};

ContentCell.propTypes = {
  value: PropTypes.string,
};

export default ContentCell;
