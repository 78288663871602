import PropTypes from "prop-types";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const ContentCell = ({ value }) => {
  return (
    <Link to={value} target="_blank">
      <Button startIcon={<OpenInNewIcon />} />
    </Link>
  );
};

ContentCell.propTypes = {
  value: PropTypes.string,
};

export default ContentCell;
