import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CopyCell = ({ value }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
  };
  return (
    <Button onClick={handleCopy} startIcon={<ContentCopyIcon />}>
      Copy
    </Button>
  );
};

CopyCell.propTypes = {
  value: PropTypes.string,
};

export default CopyCell;
