import PropTypes from "prop-types";
import { styled } from "@mui/system";

const MyComponent = styled("div")({
  whiteSpace: "pre-wrap",
  fontWeight: "bolder",
});

const SourceCell = ({ value }) => {
  return <MyComponent>{value}</MyComponent>;
};

SourceCell.propTypes = {
  value: PropTypes.string,
};

export default SourceCell;
