import useWebSocket from "react-use-websocket";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { useState } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import ContentCell from "./ContentCell";
import DateTimeCell from "./DateTimeCell";
import CopyCell from "./CopyCell";
import SourceCell from "./SourceCell";
import { alpha, styled } from "@mui/material/styles";
const ODD_OPACITY = 0.1;

const ScraperFeedTablePage = () => {
  const [clientID, setClientID] = useState("");
  const [chyronData, setChyronData] = useState([]);
  const { lastJsonMessage } = useWebSocket(
    `wss://${process.env.REACT_APP_DOMAIN}/ws/scraperfeed/?client=${clientID}`,
    {
      onOpen: () => setChyronData([]),
      shouldReconnect: () => true,
    }
  );

  useEffect(() => {
    setClientID(uuidv4());
  }, []);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.length === undefined) {
        setChyronData((prev) => [lastJsonMessage].concat(prev));
      } else {
        setChyronData((prev) => lastJsonMessage.concat(prev));
      }
    }
  }, [lastJsonMessage, setChyronData]);

  const getRows = () => {
    return chyronData.map((item, index) => ({
      id: index,
      col1: item["created_at"],
      col2: item["source"],
      col3: item["content"],
      col4: item["content"],
    }));
  };

  const columns = [
    { field: "col1", headerName: "Updated Time", flex: 1, renderCell: DateTimeCell },
    { field: "col2", headerName: "Source", flex: 1, renderCell: SourceCell },
    { field: "col3", headerName: "Content", flex: 3, renderCell: ContentCell },
    { field: "col4", headerName: "", renderCell: CopyCell },
  ];

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[300],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  return (
    <StripedDataGrid
      rows={getRows()}
      columns={columns}
      getRowHeight={() => "auto"}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
    />
  );
};

export default ScraperFeedTablePage;
