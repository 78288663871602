import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import axios from "axios";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import ContentCell from "../ChyronFeedTable/ContentCell";
import CopyCell from "../ChyronFeedTable/CopyCell";
import DateTimeCell from "../ChyronFeedTable/DateTimeCell";

const CHYRON_FEED = "Chyron Feed";
const FDA = "FDA/Orphans";
const CONTRACTS = "Contracts";
const TWITTER = "Twitter";
const ARTICLES = "Articles";
const tableList = [CHYRON_FEED, FDA, CONTRACTS, TWITTER, ARTICLES];
const ODD_OPACITY = 0.1;

const SearchingPage = () => {
  const [query, setQuery] = useState("");
  const [selectedTables, setSelectedTables] = useState(tableList);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    await handleQueryData();
  };

  const handleQueryData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/internal/search/`, {
        params: {
          query: query,
          queryType: selectedTables.join(","),
        },
      });
      setData(res.data);
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const getRows = () => {
    let result = [];
    Object.keys(data).forEach((dataType) => {
      const rows = data[dataType];

      if (dataType === "article_data") {
        result = result.concat(
          rows.map((row, index) => ({
            id: `${dataType}${index}`,
            col1: `${row["source"].trim()} ${row["content"].trim()}`,
            col2: `${row["source"].trim()} ${row["created_at"].trim()}`,
            col3: `${row["source"].trim()} ${row["content"].trim()}`,
          }))
        );
      }
      if (dataType === "chyronfeed_data") {
        result = result.concat(
          rows.map((row, index) => ({
            id: `${dataType}${index}`,
            col1: `${row["content"].trim()}`,
            col2: `${row["created_at"].trim()}`,
            col3: `${row["content"].trim()}`,
          }))
        );
      }
      if (dataType === "contract_data") {
        result = result.concat(
          rows.map((row, index) => ({
            id: `${dataType}${index}`,
            col1: `${row["awardee"].trim()} ${row["value"].trim()}`,
            col2: `${row["time"].trim()}`,
            col3: `${row["awardee"].trim()} ${row["value"].trim()}`,
          }))
        );
      }
      if (dataType === "fda_queryset") {
        result = result.concat(
          rows.map((row, index) => ({
            id: `${dataType}${index}`,
            col1: `${row["company"].trim()} ${row["drug"].trim()}`,
            col2: `${row["time"].trim()}`,
            col3: `${row["company"].trim()} ${row["drug"].trim()}`,
          }))
        );
      }
      if (dataType === "twitter_queryset") {
        result = result.concat(
          rows.map((row, index) => ({
            id: `${dataType}${index}`,
            col1: `${row["username"].trim()} ${row["text"].trim()}`,
            col2: `${row["time"].trim()}`,
            col3: `${row["username"].trim()} ${row["text"].trim()}`,
          }))
        );
      }
    });
    return result;
  };

  const columns = [
    { field: "col1", headerName: "Content", renderCell: ContentCell, flex: 8 },
    { field: "col2", headerName: "Time", renderCell: DateTimeCell, flex: 1 },
    { field: "col3", headerName: "Action", renderCell: CopyCell, flex: 1 },
  ];

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[300],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  return (
    <Paper
      elevation={2}
      sx={{
        margin: 1,
      }}
    >
      <Card>
        <div style={{ width: "100%", padding: "1rem" }}>
          <Grid container spacing={4}>
            <Grid item xl={6} sm={6} lg={6} md={12} mt={2} xs={12}>
              <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={1}>
                <form onSubmit={handleSearch} style={{ width: "100%" }}>
                  <OutlinedInput
                    onChange={(e) => setQuery(e.target.value)}
                    fullWidth
                    type="text"
                    endAdornment={
                      <InputAdornment position="end">
                        {loading ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress
                              sx={{ height: "25px  !important", width: "25px !important" }}
                            />
                          </Box>
                        ) : (
                          <IconButton type="submit">
                            <SearchIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    }
                    label=""
                  />
                </form>
              </Stack>
            </Grid>
            <Grid item xl={6} sm={6} lg={6} md={12} mt={2} xs={12}>
              <FormGroup>
                <Stack
                  direction="row"
                  justifyContent="flex-center"
                  alignItems="flex-start"
                  spacing={1}
                >
                  {tableList.map((item) => (
                    <FormControlLabel
                      md={3}
                      xs={1}
                      lg={2}
                      key={item}
                      control={
                        <Checkbox
                          checked={selectedTables.includes(item)}
                          onChange={() => {
                            let newSelectedTables;
                            if (selectedTables.includes(item)) {
                              newSelectedTables = selectedTables.filter(
                                (tableName) => tableName != item
                              );
                            } else {
                              newSelectedTables = selectedTables.concat(item);
                            }
                            setSelectedTables(newSelectedTables);
                            localStorage.setItem("tables", newSelectedTables.join(","));
                          }}
                          name={item}
                        />
                      }
                      label={item}
                    />
                  ))}
                </Stack>
              </FormGroup>
            </Grid>
          </Grid>
        </div>
        <CardContent sx={{ padding: "1rem" }}>
          <StripedDataGrid
            rows={getRows()}
            columns={columns}
            getRowHeight={() => "auto"}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        </CardContent>
      </Card>
    </Paper>
  );
};

export default SearchingPage;
