import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import axios from "axios";

import useAuth from "../../hooks/useAuth";

// Images
import bgImage from "assets/images/stock-market.jpg";

const LoginPage = () => {
  const [loginURL, setLoginURL] = useState("");
  const [searchParams] = useSearchParams();
  // const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const getGoogleLoginURL = async () => {
      const urlData = await axios.get("/api/googleapi/url/");
      setLoginURL(urlData.data.url);
    };
    getGoogleLoginURL();
  }, []);

  useEffect(() => {
    const getAuthedUser = async () => {
      const paramEntries = searchParams.entries();
      let params = {};
      for (const entry of paramEntries) {
        params[entry[0]] = entry[1];
      }
      if (Object.keys(params).length > 0) {
        login(params);
      }
    };
    getAuthedUser();
  });

  return (
    <MKBox component="header" position="relative">
      <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
        <Container>
          <Grid container flexDirection="row" alignItems="center">
            <MKTypography
              component={Link}
              href="#"
              variant="button"
              color="white"
              fontWeight="regular"
              py={0.8125}
              mr={2}
            >
              GetProNow
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} md={7} lg={6} flexDirection="column" justifyContent="center">
            <MKTypography
              variant="h1"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              New Platform
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} pr={6} mr={6}>
              Welcome to new platform. You can see all data in one place
            </MKTypography>
            <Stack direction="row" spacing={1} mt={3}>
              <MKButton color="white">
                <a href={loginURL}>Login with google</a>
              </MKButton>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
};

export default LoginPage;
