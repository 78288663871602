import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import SettingsIcon from "@mui/icons-material/Settings";

const CNN = "CNN";
const CNNUS = "CNNUS";
const CNBC = "CNBC";
const CNBCUS = "CNBCUS";
const MSNBC = "MSNBC";
const FoxNews = "FoxNews";
const FoxBusiness = "FoxBusiness";
const Bloomberg = "Bloomberg";
const tableList = [CNN, CNNUS, CNBC, CNBCUS, MSNBC, FoxNews, FoxBusiness, Bloomberg];

const SettingHeadline = ({ selectedChyrons, setSelectedChyrons }) => {
  const [openSettings, setOpenSettings] = useState(false);
  return (
    <>
      <Tooltip title="Headline setting" arrow>
        <IconButton
          onClick={() => {
            setOpenSettings(true);
          }}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={openSettings}
        onClose={() => {
          setOpenSettings(false);
        }}
        width="lg"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Settings"}</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
            <FormLabel component="label">Show Headline</FormLabel>
            <FormGroup>
              <Grid container spacing={2}>
                {tableList.map((item) => (
                  <Grid item key={item}>
                    <FormControlLabel
                      md={3}
                      xs={1}
                      lg={2}
                      control={
                        <Checkbox
                          checked={selectedChyrons.includes(item)}
                          onChange={() => {
                            let newSelectedTables;
                            if (selectedChyrons.includes(item)) {
                              newSelectedTables = selectedChyrons.filter(
                                (tableName) => tableName != item
                              );
                            } else {
                              newSelectedTables = selectedChyrons.concat(item);
                            }
                            setSelectedChyrons(newSelectedTables);
                            localStorage.setItem("chyronlist", newSelectedTables.join(","));
                          }}
                          name={item}
                        />
                      }
                      label={item}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSettings(false);
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SettingHeadline.propTypes = {
  selectedChyrons: PropTypes.array,
  setSelectedChyrons: PropTypes.func,
};

export default SettingHeadline;
