import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import axios from "axios";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const setSession = (accessToken) => {
  if (accessToken) {
    const csrftoken = Cookies.get("csrftoken");
    axios.defaults.headers["X-CSRF-TOKEN"] = csrftoken;
  } else {
    delete axios.defaults.headers;
  }
};

const handlers = {
  INITIALIZED: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, isAuthenticated } = action.payload;
    return {
      ...state,
      isAuthenticated,
      user,
    };
  },
  LOGOUT: (state) => {
    return {
      ...state,
      isInitialized: false,
      user: null,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {}, [state]);
  const doInitialize = () => {
    setSession(null);
    dispatch({
      type: "INITIALIZED",
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
  };

  const getAccountMe = async () => {
    try {
      const { data } = await axios.get("/api/googleapi/me/");
      dispatch({
        type: "INITIALIZED",
        payload: {
          isAuthenticated: true,
          user: { ...data },
        },
      });
    } catch (error) {
      doInitialize();
    }
  };

  useEffect(() => {
    const initialize = () => {
      try {
        getAccountMe();
      } catch (error) {
        doInitialize();
      }
    };
    initialize();
  }, []);

  const login = async (params) => {
    try {
      await axios.get("/api/googleapi/auth/", {
        params,
      });
      setSession(true);
      getAccountMe();
    } catch (error) {
      location.href = "/";
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any,
};

export default AuthContext;
