import PropTypes from "prop-types";
import { styled } from "@mui/system";

const MyComponent = styled("div")({
  whiteSpace: "nowrap",
  fontWeight: "bolder",
});

const ContentCell = ({ value }) => {
  return <MyComponent>{value.replace(/\n/g, " ")}</MyComponent>;
};

ContentCell.propTypes = {
  value: PropTypes.string,
};

export default ContentCell;
