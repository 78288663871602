// Material Kit 2 React components
import MKBox from "components/MKBox";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

function ProgressSimple() {
  return (
    <MKBox component="section" bgColor="white" py={12}>
      <Container component="main" maxWidth="xs">
        <Grid container item xs={12} lg={6} justifyContent="center" mx="auto">
          <Stack spacing={2} width="100%">
            <CircularProgress color="primary" />
          </Stack>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ProgressSimple;
