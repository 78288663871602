/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import LoginPage from "pages/LoginPage";
// Material Kit 2 React routes
// import routes from "routes";

import AuthGuard from "components/common/AuthGuard";
import { AuthProvider } from "contexts/AuthContext";
import LiveTVPage from "pages/Presentation/components/LiveTV";
import SearchingPage from "pages/Presentation/components/SearchingPage/SearchingPage";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getRoutes(route.collapse);
  //     }

  //     if (route.route) {
  //       return <Route exact path={route.route} element={route.component} key={route.key} />;
  //     }

  //     return null;
  //   });

  return (
    // <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Routes>
          {/* {getRoutes(routes)} */}
          <Route
            exact
            path="/"
            element={
              <AuthGuard>
                <Presentation />
              </AuthGuard>
            }
          />
          <Route
            exact
            path="/auth"
            element={
              <AuthGuard>
                <LoginPage />
              </AuthGuard>
            }
          />
          <Route
            exact
            path="/livetv"
            element={
              <AuthGuard>
                <LiveTVPage />
              </AuthGuard>
            }
          />
          <Route
            exact
            path="/search"
            element={
              <AuthGuard>
                <SearchingPage />
              </AuthGuard>
            }
          />
          {/* <Route path="*" element={<Navigate to="/auth" />} /> */}
        </Routes>
      </ThemeProvider>
    </AuthProvider>
    // </React.StrictMode>
  );
}
