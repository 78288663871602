import { useEffect, useState } from "react";
import breakpoints from "assets/theme/base/breakpoints";
import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";

const CNN = "CNN";
const CNNUS = "CNNUS";
const CNBC = "CNBC";
const CNBCUS = "CNBCUS";
const MSNBC = "MSNBC";
const FoxNews = "FoxNews";
const FoxBusiness = "Fox Business";
const Bloomberg = "Bloomberg";

const selectedChyrons = (
  localStorage.getItem("chyronlist") ||
  [CNN, CNNUS, CNBC, CNBCUS, MSNBC, FoxNews, FoxBusiness, Bloomberg].join(",")
).split(",");
const LiveTVPage = () => {
  const [imageHash, setImageHash] = useState("");
  const [imageColumn, setImageColumn] = useState(1);
  const itemData = [
    {
      img: `https://${process.env.REACT_APP_DOMAIN}/static/media/cnbc.jpg`,
      title: CNBC,
    },
    {
      img: `https://${process.env.REACT_APP_DOMAIN}/static/media/cnnus.jpg`,
      title: CNNUS,
    },
    {
      img: `https://${process.env.REACT_APP_DOMAIN}/static/media/foxnews.jpg`,
      title: FoxNews,
    },
    {
      img: `https://${process.env.REACT_APP_DOMAIN}/static/media/cnbcus.jpg`,
      title: CNBCUS,
    },
    {
      img: `https://${process.env.REACT_APP_DOMAIN}/static/media/cnn.jpg`,
      title: CNN,
    },
    {
      img: `https://${process.env.REACT_APP_DOMAIN}/static/media/foxbusiness.jpg`,
      title: FoxBusiness,
    },

    {
      img: `https://${process.env.REACT_APP_DOMAIN}/static/media/bloomberg.jpg`,
      title: Bloomberg,
    },
    {
      img: `https://${process.env.REACT_APP_DOMAIN}/static/media/msnbc.jpg`,
      title: MSNBC,
    },
  ].filter((item) => selectedChyrons.includes(item.title));

  useEffect(() => {
    const interval = setInterval(() => {
      setImageHash(Date.now());
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const displayImageList = () => {
      if (window.innerWidth < breakpoints.values.lg) {
        setImageColumn(1);
      } else {
        setImageColumn(3);
      }
    };
    window.addEventListener("resize", displayImageList);
    displayImageList();
    return () => window.removeEventListener("resize", displayImageList);
  }, []);

  return (
    <ImageList cols={imageColumn}>
      {itemData.map((item) => (
        <ImageListItem key={item.img} sx={{ padding: 1 }}>
          <img
            srcSet={`${item.img}?hash=${imageHash}`}
            src={`${item.img}?hash=${imageHash}`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar title={item.title} position="below" />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default LiveTVPage;
